<template>
    <v-row align="center" justify="center">
        <v-col cols='12' md="6">
            <v-card flat>
                <v-toolbar flat color="info">
                    <v-toolbar-title>
                        Admin Login
                    </v-toolbar-title>
                </v-toolbar>
                <v-row>
                    <v-col cols="10" offset="1">
                        <v-text-field v-model="user.username" color="secondary" label="Email"></v-text-field>
                    </v-col>
                    <v-col cols="10" offset="1">
                        <v-text-field v-model="user.password" color="secondary" label="Password" type="password"></v-text-field>
                    </v-col>
                    <v-col cols='10' offset="1" class="text-center text-md-right">
                        <v-btn outlined color="secondary" @click="login()">
                            <v-icon left>fas fa-sign-in-alt</v-icon>
                            Login
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data(){
        return {
            user: {
                password: "",
                username: ""
            }
        }
    },
    methods: {
        login(){
            this.$store.dispatch('login', this.user)
        }
    }
}
</script>